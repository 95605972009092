<template>
  <loader v-if="isLoading"/>
  <div
    v-else
    class="container"
  >
    <user-nav/>

    <div class="shop">
      <div class="shop__input-wrap">
        <input
          @input="handleSearch"
          :value="filters.fulltext"
          type="text"
          class="shop__input"
          placeholder="НАЙТИ УСЛУГУ..."
        >
      </div>

      <products-list
        :products="filteredProducts"
        :filters="filters"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import UserNav from '@/components/UserNav'
import Loader from '@/components/Loader'
import ProductsList from '@/components/ProductsList'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Shop',

  components: {
    UserNav,
    Loader,
    ProductsList
  },

  computed: {
    ...mapState({filters: state => state.cart.filters}),
    ...mapGetters(['getServices', 'getPackages']),
    searchTerm() {
      return this.filters.fulltext.trimLeft().toLowerCase()
    },
    products() {
      return [...this.getServices, ...this.getPackages].map(item => ({
        ...item,
        visible: true
      })).sort((a,b) => a.order>b.order?1:-1)
    },
    filteredProducts() {
      return this.products.map(item => ({
        ...item,
        visible: item.orderable && `${(item.name || '')}${(item.description || '')}`.toLowerCase().includes(this.searchTerm)
      }))
    }
  },

  data: () => ({
    isLoading: true,
  }),

  methods: {
    ...mapActions([
      'fetchServices', 'fetchPackages',
      'fetchCart', 'addToCart', 'removeFromCart'
    ]),
    handleSearch: debounce(function (e) {
      this.$store.commit('setFilter', {fulltext: e.target.value})
    }, 200)
},

  async mounted() {
    this.$bus.on('addToCart', this.addToCart)

    Promise.all([
      this.fetchServices(),
      this.fetchPackages(),
      this.fetchCart()
    ])
    .finally(() => {
      this.isLoading = false
    })
  },

  beforeDestroy() {
    this.$store.commit('setFilter', {fulltext: ''})

    this.$bus.off('addToCart', this.addToCart)
  }
}
</script>
